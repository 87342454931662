@layer utilities {
	@font-face {
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src:
			local("SF Pro Display"),
			url("../assets/fonts/sfprodisplayregular-webfont.woff2") format("woff2"),
			url("../assets/fonts/sfprodisplayregular-webfont.woff") format("woff");
	}
	@font-face {
		font-family: "SF Pro Display";
		font-style: italic;
		font-weight: 300;
		src:
			local("SF Pro Display"),
			url("../assets/fonts/sfprodisplaylightitalic-webfont.woff2") format("woff2"),
			url("../assets/fonts/sfprodisplaylightitalic-webfont.woff") format("woff");
	}
	@font-face {
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src:
			local("SF Pro Display"),
			url("../assets/fonts/sfprodisplaymedium-webfont.woff2") format("woff2"),
			url("../assets/fonts/sfprodisplaymedium-webfont.woff") format("woff");
	}
	@font-face {
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 700;
		font-display: swap;
		src:
			local("SF Pro Display"),
			url("../assets/fonts/sfprodisplaybold-webfont.woff2") format("woff2"),
			url("../assets/fonts/sfprodisplaybold-webfont.woff") format("woff");
	}

	@font-face {
		font-family: "Sf Pro Text";
		font-weight: 800;
		src:
			url("../assets/fonts/sf-pro-text-regular-webfont.woff2") format("woff2"),
			url("../assets/fonts/sf-pro-text-regular-webfont.woff") format("woff");
		font-display: swap;
	}

	@font-face {
		font-family: "era";
		src: url("../assets/fonts/era.eot?n99m8o");
		src:
			url("../assets/fonts/era.eot?n99m8o#iefix") format("embedded-opentype"),
			url("../assets/fonts/era.ttf?n99m8o") format("truetype"),
			url("../assets/fonts/era.woff?n99m8o") format("woff"),
			url("../assets/fonts/era.svg?n99m8o#era") format("svg");
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}
}
