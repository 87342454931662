.autoComplete {
  background: var(--white);
  color: var(--color-grey-black);
  box-shadow: 0px 0px 20px 0px var(--box-shadow);
  border-radius: 10px;
  padding: 40px;
  z-index: 5;

  @media (max-width: 360px) {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }
}

.suggest {
  margin: 7px 0;
  cursor: pointer;
  display: flex;
  white-space: nowrap;

  @media (max-width: 360px) {
    .fs-18px {
      font-size: 16px;
    }
  }
}

@media (min-width: 820px) {
  .suggest:hover {
    margin: 7px 0;
    cursor: pointer;
    color: var(--color-blue-medium);

    app-text-round-separator {
      .separator {
        div {
          background-color: var(--color-blue-medium) !important;
        }
      }
    }
  }
}

.selectStyle {
  margin: 7px 0;
  cursor: pointer;
  color: var(--color-blue-medium);
}
