@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";

@use "styles/constants";
@use "styles/base" as base2;
@use "styles/icons";
@use "styles/fonts";
@use "styles/layout";
@use "styles/autocomplete";
@use "styles/mapbox";

html, body {
	min-width: 320px;
}

body {
	margin: 0;
	height: 100%;
	font-family: "SF Pro Display";
	font-size: 16px;
}

.no-scroll {
	position: fixed;
	overflow: hidden;
	width: 100%;
}

.hidden-lg {
	@media (min-width: 1024px) {
		display: none !important;
	}
}

@layer utilities {
	.h-80px {
		height: 80px;
	}

	.bg-custom {
		background: no-repeat center center static;
		background-size: cover;
	}

	// TITLES
	.display-h1 {
		font-size: 52px;
		font-family: "Sf Pro Display";
		line-height: 76px;
		font-weight: 800;
		letter-spacing: 0.03em;
		text-decoration: none;

		&-34px {
			font-size: 34px;
			font-family: "Sf Pro Display";
			font-weight: 700;
			font-style: normal;
			line-height: 46px;
			letter-spacing: 0.04em;
			text-decoration: none;

			@media (max-width: constants.$bp-tablet) {
				font-size: 24px;
			}
		}

		&-regular {
			font-size: 24px;
			font-family: "SF Pro Display";
			font-style: normal;
			line-height: 32px;
			letter-spacing: 0.01em;
			text-decoration: none;

			@media (max-width: constants.$bp-tablet) {
				font-size: 20px;
			}
		}
	}

	.display-h2 {
		font-size: 34px;
		font-family: "Sf Pro Display";
		font-weight: 700;
		font-style: normal;
		line-height: 46px;
		letter-spacing: 0.04em;
		text-decoration: none;

		// @media (min-width: 1800px) {
		// 	font-size: 2.36vw;
		// }
		@media (max-width: constants.$bp-tablet) {
			font-size: 24px;
			line-height: 32px;
		}
	}

	.display-h3 {
		font-size: 32px;
		font-family: "Sf Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 46px;
		letter-spacing: 0.04em;

		// @media (min-width: 1800px) {
		// 	font-size: 2.2vw;
		// }

		&-28px {
			font-size: 28px;
			font-family: "SF Pro Display";
			font-weight: 700;
			font-style: normal;
			line-height: 30px;
			letter-spacing: 0.02em;
			text-decoration: none;

			// @media (min-width: 1800px) {
			// 	font-size: 1.94vw;
			// }
		}
	}

	.display-h4 {
		font-size: 28px;
		font-family: "SF Pro Display";
		font-weight: 700;
		font-style: normal;
		line-height: 30px;
		letter-spacing: 0.02em;
		text-decoration: none;

		// @media (min-width: 1800px) {
		// 	font-size: 1.94vw;
		// }
	}

	.display-h5 {
		font-size: 24px;
		font-family: "SF Pro Display";
		line-height: 32px;
		font-weight: 600;
		letter-spacing: 0.01em;
		text-decoration: none;

		@media (max-width: constants.$bp-tablet) {
			font-size: 20px;
			font-weight: 600;
		}

		// @media (min-width: 1800px) {
		// 	font-size: 1.67vw;
		// }
	}

	.display-text {
		&-10px {
			font-size: 10px;
			font-family: "SF Pro Display";
			font-style: normal;
			letter-spacing: 0.04em;
			line-height: 12px;

			// @media (min-width: 1800px) {
			// 	font-size: 0.69vw;
			// }
		}

		&-12px {
			font-size: 12px;
			font-family: "SF Pro Display";
			font-style: normal;
			line-height: 24px;
			letter-spacing: 0.01em;

			// @media (min-width: 1800px) {
			// 	font-size: 0.83vw;
			// }
		}

		&-14px {
			font-size: 14px;
			font-family: "SF Pro Display";
			font-style: normal;
			line-height: 20px;
			letter-spacing: 0.02em;

			// @media (min-width: 1800px) {
			// 	font-size: 0.97vw;
			// }
		}

		&-15px {
			font-size: 15px;
			font-family: "SF Pro Display";
			font-style: normal;
			line-height: 26px;
			letter-spacing: 0.01em;

			// @media (min-width: 1800px) {
			// 	font-size: 1.04vw;
			// }
		}

		&-16px {
			font-size: 16px;
			font-family: "SF Pro Display";
			font-style: normal;
			line-height: 28px;
			letter-spacing: 0.02em;

			// @media (min-width: 1800px) {
			// 	font-size: 1.11vw;
			// }
		}

		&-18px {
			font-size: 18px;
			font-family: "SF Pro Display";
			font-style: normal;
			line-height: 36px;
			letter-spacing: 0.04em;

			@media (max-width: constants.$bp-tablet) {
				font-size: 14px;
				line-height: 30px;
			}

			// @media (min-width: 1800px) {
			// 	font-size: 1.25vw;
			// }
		}

		&-20px {
			font-size: 20px;
			font-family: "SF Pro Display";
			font-style: normal;
			letter-spacing: 0.02em;

			// @media (min-width: 1800px) {
			// 	font-size: 1.39vw;
			// }
		}

		&-24px {
			font-size: 24px;
			font-family: "SF Pro Display";
			font-style: normal;
			line-height: 38px;
			letter-spacing: 0.01em;
			font-weight: 400;

			// @media (min-width: 1800px) {
			// 	font-size: 1.67vw;
			// }
		}
	}
}

.title-padding {
	padding-bottom: 65px;

	@media (max-width: constants.$bp-tablet) {
		padding-bottom: 40px;
	}
}
.display-block {
	display: block;
}

/* You can add global styles to this file, and also import other style files */

html {
	overflow: scroll;
	overflow-x: hidden;
}
// ::-webkit-scrollbar {
// 	width: 0; /* Remove scrollbar space */
// 	background: transparent; /* Optional: just make scrollbar invisible */
// }

// SCROLLBAR
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	display: none;
}

::-webkit-scrollbar-thumb {
	background: var(--color-grey-medium);
	border-radius: 10px;
}

sup {
	font-size: 50%;
	top: -0.4rem;
}

.container {
	margin-left: auto;
	margin-right: auto;
	padding: 15px;
}

.row {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 20px;
	margin-bottom: 10px;
}

.right-0 {
	right: 0;
}

@for $i from 1 through 12 {
	.col-#{$i} {
		grid-column: span $i;
	}
}

.customTooltipClass {
	background-color: #f50707;
	padding: 10px;
	border-radius: 35px;
	border: 2px solid #363434;
	color: white;
	box-shadow:
		0 6px 6px rgba(0, 0, 0, 0.16),
		0 6px 6px rgba(0, 0, 0, 0.23);
}

.customToaster {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;
	background-color: #f50707;
	padding: 10px;
	border-radius: 35px;
	border: 2px solid #363434;
	color: white;
	box-shadow:
		0 6px 6px rgba(0, 0, 0, 0.16),
		0 6px 6px rgba(0, 0, 0, 0.23);
}

input::placeholder {
	color: #1d1d1d;
	font-family: "SF Pro Display";
}
input[type="checkbox"] {
	min-width: 13px;
	min-height: 13px;
}

input:focus,
input.form-control:focus {
	outline: none !important;
	outline-width: 0 !important;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}

.pagination .pagination-previous,
.pagination-next {
	width: 32px;
	height: 32px;
	background: #ffffff;
	box-shadow: 0px 0px 20px 0px var(--box-shadow);
	border-radius: 100px;
	cursor: pointer;
	margin: 4px 15px 0 15px;

	@media (max-width: constants.$bp-tablet) {
		margin: 4px 0;
	}
}

.pagination .cur {
	background-color: #0c3279;
	border-radius: 4px;
	min-height: 40px;
	min-width: 40px;
	margin: 0 5px 0 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: white;
	@media (max-width: constants.$bp-tablet) {
		min-width: 30px;
		font-size: 14px;
	}
}

.pagination .unactive :not(.dots) {
	background: #ffffff;
	box-shadow: 0px 0px 20px 0px var(--box-shadow);
	border-radius: 4px;
	min-height: 40px;
	min-width: 40px;
	margin: 0 5px 0 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #898989;

	@media (max-width: constants.$bp-tablet) {
		min-width: 30px;
		font-size: 14px;
	}
}

.pagination .dots {
	color: #0c3279;
	min-height: 40px;
	min-width: 40px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	cursor: pointer;
}

// Classes for px perfect

@for $i from 1 through 1000 {
	.h-#{$i}px {
		height: #{$i}px;
	}
	.min-h-#{$i}px {
		min-height: #{$i}px;
	}
	.max-h-#{$i}px {
		max-height: #{$i}px;
	}
	.w-#{$i}px {
		width: #{$i}px;
	}
	.min-w-#{$i}px {
		min-width: #{$i}px;
	}
	.max-w-#{$i}px {
		max-width: #{$i}px;
	}
	.lh-#{$i}px {
		line-height: #{$i}px;
	}
	.text-#{$i}px {
		font-size: #{$i}px;
	}
	.fs-#{$i}px {
		font-size: #{$i}px;
	}
	.bottom-#{$i}px {
		bottom: #{$i}px;
	}
	.top-#{$i}px {
		top: #{$i}px;
	}
	.left-#{$i}px {
		left: #{$i}px;
	}
	.right-#{$i}px {
		right: #{$i}px;
	}
	.p-#{$i}px {
		padding: #{$i}px;
	}
	.pl-#{$i}px {
		padding-left: #{$i}px;
	}
	.pr-#{$i}px {
		padding-right: #{$i}px;
	}
	.pt-#{$i}px {
		padding-top: #{$i}px;
	}
	.pb-#{$i}px {
		padding-bottom: #{$i}px;
	}
	.py-#{$i}px {
		padding-top: #{$i}px;
		padding-bottom: #{$i}px;
	}
	.px-#{$i}px {
		padding-left: #{$i}px;
		padding-right: #{$i}px;
	}
	.m-#{$i}px {
		margin: #{$i}px;
	}
	.ml-#{$i}px {
		margin-left: #{$i}px;
	}
	.mr-#{$i}px {
		margin-right: #{$i}px;
	}
	.mt-#{$i}px {
		margin-top: #{$i}px;
	}
	.mb-#{$i}px {
		margin-bottom: #{$i}px;
	}
	.my-#{$i}px {
		margin-top: #{$i}px;
		margin-bottom: #{$i}px;
	}
	.mx-#{$i}px {
		margin-right: #{$i}px;
		margin-left: #{$i}px;
	}
	.br-#{$i}px {
		border-radius: #{$i}px;
	}
}

@media (max-width: 900px) {
	.pl-40px {
		padding-left: 20px;
	}
	.ml-10 {
		margin-left: 20px;
	}
	.mx-11 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.mr-80px,
	.mr-51px {
		margin-right: initial;
	}
	.mt-80px:not(.landing):not(.allnegociator):not(.common-page):not(.negociator):not(.annonce) {
		margin-top: 20px;
	}
	.offre.mt-80px {
		margin-top: 80px !important;
	}
	.common-page-title.mt-80px {
		margin-top: 80px !important;
	}
	.mt-80px.annonce {
		margin-top: 0;
	}
	.w-290px,
	.w-2\/3,
	.w-1\/3 {
		width: 100%;
	}
	.px-40px {
		padding-left: 20px;
		padding-right: 20px;
	}
	.h-140px {
		height: 80px;
	}

	.top-140px {
		top: 120px;
	}

	.titlePage {
		flex-direction: column;
		align-items: flex-start;
		.ml-10px {
			margin-left: 0;
		}
	}
}

@media (max-width: 300px) {
	.w-275px {
		width: 200px;
	}
}

//TAGS
.red-tag-container {
	position: absolute;
	top: 12px;
	left: 12px;
	right: 12px;
	display: flex;
	justify-content: space-between;
	.red-tag {
		margin-right: 5px;
		&:last-child {
			margin-right: 0;
		}
		&.seen {
			background-color: var(--white);
			color: var(--color-blue-dark);
			border: 1px solid var(--color-blue-dark);
			position: absolute;
			right: 0;
		}
		&.heart-throb {
			margin-top: 5px;
		}
	}
}
.red-tag {
	background-color: var(--color-red-medium);
	display: flex;
	align-items: center;
	height: 26px;
	padding: 0 8px;
	border-radius: 2px;
	color: white;
	line-height: 16px;
	letter-spacing: 0.04em;
	font-weight: 500;
	z-index: 1;
	text-transform: uppercase;
	width: fit-content;
}

.img-tag {
	background-color: var(--color-grey-su-super-dark);
	display: flex;
	align-items: center;
	bottom: 12px;
	left: 12px;
	height: 26px;
	padding: 0 8px;
	border-radius: 2px;
	color: white;
	line-height: 16px;
	letter-spacing: 0.04em;
	font-weight: 500;
	z-index: 1;
	opacity: 0;
	margin-bottom: 10px;
	transition: opacity 350ms;

	@media (max-width: 1000px) {
		position: absolute;
	}
}

.hide-property {
	background-color: var(--color-grey-su-super-light);
	color: var(--color-grey-light);
	border: 1px solid var(--color-grey-light);
	display: flex;
	align-items: center;
	bottom: 12px;
	right: 12px;
	height: 26px;
	padding: 0 8px;
	border-radius: 2px;
	line-height: 16px;
	letter-spacing: 0.04em;
	font-weight: 500;
	z-index: 1;
	opacity: 0;
	margin-bottom: 10px;
	transition: opacity 350ms;
}

.publication-date-tag {
	position: absolute;
	top: 12px;
	right: 12px;
	color: var(--white);
	padding: 6px 8px;
	text-transform: uppercase;
	border-radius: 2px;
	background: black;
	opacity: 0;
	transition: opacity 350ms;
}

.social-network-tag {
	position: absolute;
	display: flex;
	align-items: center;
	bottom: 12px;
	left: 12px;
	height: 50px;
	color: white;
	opacity: 0;
	transition: opacity 350ms;
	background: var(--white);
	width: fit-content;
	border-radius: 10px;
	padding: 15px;

	.network {
		display: flex;

		&-item {
			width: 35px;
			height: 35px;
			border: 1px solid var(--color-red-dark);
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			text-align: center;
			border-radius: 10px;
			margin-right: 10px;
			box-shadow: 0px 0px 20px 0px var(--box-shadow);
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			img[alt="facebook"] {
				width: 100%;
			}
			img[alt="twitter"] {
				width: 100%;
			}
		}
	}
}

//BUTTONS
.loginBtn {
	box-sizing: border-box;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	/* width: 13em;  - apply for fixed size */
	padding: 16px 20px;
	border: 2px solid #f2f5f8;
	text-align: left;
	height: 52px;
	white-space: nowrap;
	font-size: 16px;
	border-radius: 10px;
	color: var(--color-grey-super-dark);
	span {
		margin-right: 15px;
	}
}

.btn-arrow {
	&-red-dark {
		background: var(--color-red-dark);
		box-shadow: 0px 5px 25px rgba(145, 3, 55, 0.15);
		color: white;
		padding: 0 37px;
		min-height: 48px;
		border-radius: 10px;
		width: fit-content;
		font-size: 16px;
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: 0.01em;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		position: relative;

		&.disabled,
		&:disabled {
			cursor: default;
			background-color: var(--color-grey-medium);
			border: 1px solid var(--color-grey-medium);
			pointer-events: none;
		}

		@media (max-width: 920px) {
			padding: 0 15px;
			text-align: center;
		}
	}

	&-blue {
		background: var(--white);
		color: var(--color-blue-dark);
		border: 1px solid var(--color-blue-dark);
		padding: 0 37px;
		min-height: 48px;
		border-radius: 10px;
		width: fit-content;
		font-size: 16px;
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: 0.01em;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		position: relative;

		@media (max-width: 920px) {
			padding: 0 15px;
			text-align: center;
		}
	}

	&-white {
		background: var(--white);
		color: var(--color-grey-su-super-dark);
		border: 1px solid var(--color-grey-su-super-dark);
		padding: 0 37px;
		min-height: 48px;
		border-radius: 10px;
		width: fit-content;
		font-size: 16px;
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: 0.01em;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		position: relative;

		@media (max-width: 920px) {
			padding: 0 15px;
			text-align: center;
		}
	}

	&-red-dark:hover,
	&-blue:hover,
	&-white:hover {
		.opacity-transition {
			transition: opacity 350ms;
			opacity: 1;
		}
		.text-transition {
			transform: translateX(-17px);
			transition: transform 350ms;
		}
	}
}

.big-btn {
	width: -webkit-fill-available;

	.opacity-transition {
		transition: opacity 350ms;
		opacity: 0;
		position: absolute;
		right: 90px;
	}
}

.btn-phone {
	&-blue-border {
		color: #0c3279;
		min-width: 210px;
		border: solid 1px #0c3279;
		padding: 0 59px;
		min-height: 48px;
		border-radius: 10px;
		font-size: 16px;
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: 0.01em;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;
	}

	&-red-border {
		color: var(--color-red-dark);
		border: solid 1px var(--color-red-dark);
		padding: 0 59px;
		min-height: 48px;
		border-radius: 10px;
		width: fit-content;
		font-size: 16px;
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: 0.01em;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		position: relative;
		min-width: 200px;
		text-align: center;
	}

	&-blue-border:hover,
	&-red-border:hover {
		.phone-transition {
			transition: opacity 350ms;
			opacity: 1;
		}
		.arrow-transition,
		.icon-arrow-right {
			transition: opacity 350ms;
			opacity: 1;
		}
		.text-transition {
			transform: translateX(-17px);
			transition: transform 350ms;
		}
	}

	// @media (min-width: 1800px) {
	// 	font-size: 1.11vw;
	// }
}

.text-transition {
	transition: transform 350ms;
}

.opacity-transition {
	transition: opacity 350ms;
	opacity: 0;
	position: absolute;
	right: 20px;
}

.phone-transition {
	transition: opacity 350ms;
	opacity: 0;
	position: absolute;
	right: 63px;
}

.arrow-transition {
	transition: opacity 350ms;
	opacity: 0;
	position: absolute;
	right: 63px;
	top: 50%;
	transform: translateY(-50%);

	&.big {
		right: -60px;
	}
}

.statement-agence-card-contact {
	a {
		.arrow-transition {
			transition: opacity 350ms;
			opacity: 0;
			position: absolute;
			right: 14px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.marker-agence,
.marker-team {
	background: url("./assets/imgs/era-logo.svg") no-repeat scroll right center white;
	background-size: contain;
	background-origin: content-box;
	width: 25px;
	height: 33.33px;
	cursor: pointer;
}

.marker-annonces {
	width: 14px;
	height: 14px;
	border-radius: 100px;
	border: solid white 2px;
	background-color: var(--color-red-medium);
	cursor: pointer;
	display: flex;
	justify-content: center;
	z-index: 1;

	@media (max-width: 900px) {
		width: 125px;
		height: 125px;
	}
}

.marker-annonces:hover {
	position: relative;
	z-index: 2;

	.price-marker {
		width: 89px;
		height: 33px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		bottom: 10px;
		border-radius: 4px;
		filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.05));
		font-weight: 500;
		letter-spacing: 0.01em;
		line-height: 16.3px;
		visibility: visible;
		pointer-events: none;
	}

	.arrow-marker {
		width: 8px;
		height: 8px;
		background-color: white;
		transform: rotate(45deg);
		border-radius: 2px;
		position: absolute;
		bottom: 6px;
	}
}

.agence-markerBis {
	width: auto;
	max-width: none !important;

	.mapboxgl-popup-content {
		min-height: auto;
		max-width: none !important;
		width: auto;
		padding: 5px 10px;
		white-space: nowrap;
		font-weight: 600;
	}
}

.price-markerBis {
	z-index: 1;

	.mapboxgl-popup-content {
		font-family: "Open Sans", sans-serif;
		min-height: 33px;
		height: -moz-fit-content;
		height: fit-content;
		border-radius: 4px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.05));
		white-space: nowrap;
	}

	&.precision {
		.mapboxgl-popup-content {
			background-color: var(--color-grey-li-light);
		}

		.mapboxgl-popup-tip {
			border-top-color: var(--color-grey-li-light);
		}
	}
}

.price-marker {
	visibility: hidden;
}

.mapboxgl-popup {
	z-index: 9;
	max-width: 260px !important;
}

.mapboxgl-popup-content {
	font-family: "Open Sans", sans-serif;
	padding: 10px;
	min-height: 214px;
	height: fit-content;
	border-radius: 10px;
	cursor: pointer;

	.enseigne-text {
		line-height: 19px;
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: 500;
	}

	.adresse-text {
		font-weight: 500;
		line-height: 12px;
	}

	.annonce-details {
		margin-left: 15px;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.prix {
		margin-top: 10px;
		line-height: 28px;
		font-weight: 600;
		color: var(--color-blue-dark);
	}

	.squareMeter {
		font-weight: 600;
		color: var(--color-grey-su-super-dark);
	}

	.details {
		line-height: 19px;
		color: var(--color-grey-su-super-dark);
		font-weight: 600;
		margin-top: 2px;
	}

	.type-annonce {
		margin-top: 10px;
		line-height: 26px;
		color: var(--color-grey-su-super-dark);
	}

	.img-container-popup {
		width: auto;
		height: auto;
		border-radius: 0;
		position: relative;

		a,
		a:visited {
			outline: none;
		}

		.chevron-left {
			position: absolute;
			left: 20px;
			top: 50%;
			transform: translateY(-50%);
			width: 32px;
			height: 32px;
			background: white;
			border-radius: 100px;
			box-shadow: 0px 0px 20px 0px var(--box-shadow);
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 1;
			transition: opacity 350ms;
			cursor: pointer;

			@media (max-width: 900px) {
				width: 40px;
				height: 40px;
				font-size: 25px;
			}
		}

		.chevron-right {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			width: 32px;
			height: 32px;
			background: white;
			border-radius: 100px;
			box-shadow: 0px 0px 20px 0px var(--box-shadow);
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 1;
			transition: opacity 350ms;
			cursor: pointer;

			@media (max-width: 900px) {
				width: 40px;
				height: 40px;
				font-size: 25px;
			}
		}
	}
}

#instructions {
	position: relative;
	width: 100%;
	background-color: transparent;
	overflow-y: scroll;
	font-family: sans-serif;
	z-index: 0;
	height: 83%;

	p {
		position: relative;
		z-index: 1;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.75);
		color: #fff;
		padding: 5px 10px;
		font-size: 15px;
		line-height: 28px;
		padding-bottom: 20px;

		strong {
			font-weight: bold;
		}
	}

	ol {
		list-style: auto;
		background: rgba(0, 0, 0, 0.75);
		margin: 7px 0 0;
		color: #fff;
		padding-bottom: 20px;

		li {
			line-height: 24px;
			padding-bottom: 5px;
			margin-left: 35px;
		}
	}
}

.markerA {
	background-size: contain;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #3bb2d0;
	background-image: url("../src/assets/imgs/A.svg");
	background-repeat: no-repeat;
}

.markerB {
	background-size: contain;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #8a8acb;
	background-image: url("../src/assets/imgs/B.svg");
	background-repeat: no-repeat;
}

.mapboxgl-ctrl-geocoder {
	width: 100% !important;
	max-width: initial !important;
	min-width: initial !important;
	font-size: 13px !important;

	&::after {
		content: "";
		background: url("../src/assets/imgs/B.svg") center center no-repeat;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 30px;
		background-color: #8a8acb;
	}

	svg {
		display: none;
	}
}

.mapboxgl-popup-tip {
	z-index: 9;
}
.mapboxgl-popup-close-button {
	display: none;
}
.mapboxgl-ctrl-bottom-right {
	bottom: 0 !important;
	right: 0;
	display: flex;
	align-items: flex-end;
}
// .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
// 	margin: 0 20px 20px 0;
// 	float: right;
// 	bottom: 0px;
// 	height: 100px;
// }
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
	display: none;
}
.mapboxgl-ctrl-group {
	background: transparent;
}
.mapboxgl-ctrl-group:not(:empty) {
	box-shadow: none;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.mapboxgl-ctrl-group button + button {
	border-top: none;
}
.mapboxgl-ctrl-group button {
	width: 40px;
	height: 40px;
	background-color: white;
	border-radius: 4px;
}
.mapboxgl-ctrl-group button:hover {
	background-color: white !important;
	border-radius: 4px !important;
}
.img-in-popup {
	width: 210px;
	height: 112px;
	border-radius: 10px 10px 0px 0px;
	user-select: none;
}
.mapboxgl-ctrl-compass {
	display: none !important;
}
.mapboxgl-control-container {
	.mapbox-directions-profile {
		display: flex;
		justify-content: center;
		label {
			transform: initial !important;
		}
	}
	.mapbox-directions-origin {
		.mapboxgl-ctrl-geocoder input[type="text"] {
			pointer-events: none;
		}
	}
	.mapboxgl-ctrl-geocoder .geocoder-pin-right *.active,
	#mapbox-directions-profile-driving-traffic,
	label[for="mapbox-directions-profile-driving-traffic"] {
		display: none;
	}
}
// .mapboxgl-ctrl.mapboxgl-ctrl-group {
// 	@media (max-width: 900px) {
// 		position: relative;
// 		bottom: 40px;
// 	}
// }

.closeDrawModal {
	position: absolute;
	right: 40px;
	height: 40px;
	width: 40px;
	background-color: white;
	box-shadow: 0px 0px 20px 0px var(--box-shadow);
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.blue-icon {
	color: var(--color-blue-dark);
}
.grey-icon {
	color: var(--color-grey-medium);
}

.map-mapbox {
	// width: 44%;
	// height: calc(100% - $full-header-height);
	height: 100%;
}

.map-container {
	position: relative;
	height: calc(100vh - constants.$full-header-height);
	width: 50%;

	&.displayMap {
		display: block;
	}

	@media (max-width: 900px) {
		position: fixed;
		display: none;
		width: 100%;
		top: constants.$full-header-height;
		z-index: 1;
		left: 0;

		.mapboxgl-canvas-container {
			height: 100vh;
		}

		#map-mapbox-achat,
		#map-mapbox-agence {
			position: absolute;
			bottom: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.map-draw-container {
	position: relative;
	height: calc(100vh - constants.$header-height);
	width: 100%;
}

div#mapbox-directions-origin-input:after {
	content: " Emplacement du bien";
	position: absolute;
	left: 40px;
	padding-left: 10px;
	z-index: 1;
	top: 0;
	background: white;
	width: calc(100% - 40px);
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 12px;
}

.img-container {
	height: 14.94vw;
	overflow: hidden;
	position: relative;
	border-radius: 10px;
	user-select: none;
	background: #eff1f6 no-repeat;
	z-index: 1;

	@media (max-width: 900px) {
		height: 180px;
	}
}
.img-container:hover {
	.img-tag {
		background-color: var(--color-grey-su-super-dark);
		display: flex;
		align-items: center;
		bottom: 12px;
		left: 12px;
		height: 26px;
		padding: 0 8px;
		border-radius: 2px;
		color: white;
		line-height: 16px;
		letter-spacing: 0.04em;
		font-weight: 500;
		z-index: 1;
		opacity: 1;
		transition: opacity 350ms;
	}
	.chevron-left {
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		background: white;
		border-radius: 100px;
		box-shadow: 0px 0px 20px 0px var(--box-shadow);
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 1;
		transition: opacity 350ms;
		cursor: pointer;
	}
	.chevron-right {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		width: 30px;
		height: 30px;
		background: white;
		border-radius: 100px;
		box-shadow: 0px 0px 20px 0px var(--box-shadow);
		font-size: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 1;
		transition: opacity 350ms;
		cursor: pointer;
	}

	.hide-property {
		background-color: var(--color-grey-su-super-light);
		color: var(--color-grey-light);
		border: 1px solid var(--color-grey-light);
		display: flex;
		align-items: center;
		bottom: 12px;
		right: 12px;
		height: 26px;
		padding: 0 8px;
		border-radius: 2px;
		line-height: 16px;
		letter-spacing: 0.04em;
		font-weight: 500;
		z-index: 99;
		opacity: 1;
		cursor: pointer;
	}
}

.chevron-left {
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	background: white;
	border-radius: 100px;
	box-shadow: 0px 0px 20px 0px var(--box-shadow);
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 350ms;
	cursor: pointer;
}
.chevron-right {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	background: white;
	border-radius: 100px;
	box-shadow: 0px 0px 20px 0px var(--box-shadow);
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 350ms;
	cursor: pointer;
}

.smallLogo {
	transition: all 300ms ease;
	height: 70.19px;
  min-width: 52px;
  width: 52px;
}

.hideAutocomplete {
	display: none;
}

.filter-mobile-back {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	height: 80px;
	display: none;
	box-shadow: 0px 10px 20px rgba(236, 236, 236, 0.5);
	align-items: center;
	background-color: var(--white);
	padding-left: 20px;
	z-index: 10001;

	p {
		padding-left: 12px;
		font-weight: 500;
		font-size: 20px;
	}

	@media (max-width: 900px) {
		display: flex;
		align-content: center;
	}
}

.previous-button-btn {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;

	.icon-arrow-left {
		height: 32px;
		width: 32px;
		border-radius: 50%;
		position: relative;
		box-shadow: 0px 0px 20px 0px #ececec;

		&::before {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.action-button-previous {
		background: transparent;
		margin-left: 10px;
		cursor: pointer;
	}
}
.asterisque {
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.04em;
	color: var(--color-grey-medium);

	&-big {
		padding-top: 30px;
		text-transform: uppercase;
		text-align: center;
	}
}

.color-location {
	color: var(--color-grey-medium);
	font-size: 20px;
	margin-right: 8px;
}

.scale label .arrow {
	align-items: center !important;
}
.scale label[for="dpe-field-A"] .arrow {
	width: 16% !important;
}

@media (max-width: 900px) {
	.bigLogo {
		height: 70.19px;
	}
}

.map-loader,
.map-loader-sticky {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 21;
	display: flex;
	align-items: center;
	.background-opacifier {
		background-color: var(--color-grey-li-light);
		opacity: 0.6;
		height: 100%;
		width: 100%;
	}
	.map-loader {
		background-color: var(--white);
		padding: 25px 20px;
		display: flex;
		flex-direction: column;
		height: max-content;
		width: max-content;
		border-radius: 10px;
		margin: auto;
	}
}

.map-loader-sticky {
	position: fixed;
}

.card-metiers {
	ul {
		list-style-type: disc;

		li {
			margin-left: 33px;
			list-style-type: disc;

			@media (max-width: constants.$bp-tablet) {
				font-size: 14px;
				line-height: 30px;
			}
		}
	}
}

.desktop {
	display: flex;
}

.mobile,
.tablet {
	display: none;
}

@media (max-width: constants.$bp-tablet) {
	.mobile {
		display: flex;
	}
	.tablet {
		display: flex;
	}
	.desktop {
		display: none;
	}
}

@media (max-width: constants.$bp-phone) {
	.mobile {
		display: flex;
	}
	.tablet {
		display: none;
	}
	.desktop {
		display: none;
	}
}

// BO DONNEES PERSONNELLES
.personal-data {
	.main-containers {
		&.data-personal {
			h2 {
				font-size: 20px;
				font-weight: 700;
			}
			a {
				color: var(--color-blue-medium);
				text-decoration: underline;
			}
			b {
				font-weight: bold;
			}
			div {
				font-size: 18px;
				font-family: "Sf Pro Display";
			}
		}

		ul {
			list-style-type: disc;

			li {
				margin-left: 33px;
				font-size: 18px;
				line-height: 34px;
				font-family: "Sf Pro Display";

				@media (max-width: constants.$bp-tablet) {
					font-size: 14px;
					line-height: 30px;
				}
			}
		}

		table {
			width: 100%;
			min-width: 600px;
			th,
			td {
				border: 1px solid black;
				padding: 10px;
				vertical-align: middle;
				font-size: 18px;
				font-family: "Sf Pro Display";
			}
		}
	}
}

// BO MENTIONS LEGALES
.legal-notice {
	.main-containers {
		&.data-legal {
			h2 {
				font-size: 20px;
				font-weight: 700;
			}
			a {
				color: var(--color-blue-medium);
				text-decoration: underline;
			}
			b {
				font-weight: bold;
			}
			div {
				font-size: 18px;
				font-family: "Sf Pro Display";
			}
		}

		ul {
			list-style-type: disc;

			li {
				margin-left: 33px;
				font-size: 18px;
				line-height: 34px;

				@media (max-width: constants.$bp-tablet) {
					font-size: 14px;
					line-height: 30px;
				}
			}
		}

		table {
			width: 100%;
			min-width: 600px;
			th,
			td {
				border: 1px solid black;
				padding: 10px;
				vertical-align: middle;
			}
		}
	}
}

// POSTULER
.offre-agence {
	.offre-agence-poste {
		font-family: "Sf Pro Display";

		h2 {
			font-size: 20px;
			font-weight: 700;
		}

		a {
			color: var(--color-blue-medium);
			text-decoration: underline;
		}

		b {
			font-weight: bold;
		}
	}

	.card {
		ul {
			list-style-type: disc;
			margin: 10px auto;

			li {
				margin-left: 33px;
			}
		}

		ul > li, p {
			font-size: 18px;
			line-height: 34px;

			@media (max-width: constants.$bp-tablet) {
				font-size: 14px;
				line-height: 30px;
			}
		}
	}
}

// GUIDE
.guide {
	.main-containers {
		&.semantique {
			h2 {
				font-size: 34px;
				font-weight: 700;
				margin-bottom: 20px;
				margin-top: 80px;
			}
			h3 {
				font-size: 28px;
				font-weight: 700;
				margin-bottom: 20px;
			}
			h4 {
				font-size: 20px;
				font-weight: 700;
				margin-bottom: 20px;
			}
			a {
				color: var(--color-blue-medium);
				text-decoration: underline;
			}
			b {
				font-weight: bold;
			}
			div,
			p {
				font-size: 18px;
				font-family: "Sf Pro Display";
				line-height: 44px;
			}
		}

		ul {
			list-style-type: disc;

			li {
				margin-left: 33px;
				font-size: 18px;
				line-height: 34px;

				@media (max-width: constants.$bp-tablet) {
					font-size: 14px;
					line-height: 30px;
				}
			}
		}

		table {
			width: 100%;
			min-width: 600px;
			th,
			td {
				border: 1px solid black;
				padding: 10px;
				vertical-align: middle;
			}
		}
	}
}

app-bottom-content {
	.content {
		.content-container {
			.display-text-16px {
				a {
					&.bottomContentLink {
						color: var(--color-blue-medium);
					}
				}
			}
		}
	}
}

app-article {
	.article {
		.page {
			.article-containers {
				strong {
					font-weight: bold;
				}

				em,
				i {
					font-style: italic;
				}

				a {
					color: var(--color-blue-medium);
					text-decoration: underline;
				}

				ul {
					list-style-type: disc;

					li {
						margin-left: 33px;
						font-size: 18px;
						line-height: 34px;

						@media (max-width: constants.$bp-tablet) {
							font-size: 14px;
							line-height: 30px;
						}
					}
				}

				iframe {
					width: 400px;
					height: 300px;
					border-radius: 10px;
				}

				h1 {
					font-size: 52px;
					font-family: "Sf Pro Display";
					font-weight: 800;
				}

				h2 {
					font-size: 34px;
					font-family: "Sf Pro Display";
					font-weight: 700;

					&.display-h2 {
						font-size: 38px;
						font-family: "Sf Pro Display";
						font-weight: 800;
					}
				}

				h3 {
					font-size: 32px;
					font-family: "Sf Pro Display";
					font-weight: 600;
				}

				h4 {
					font-size: 28px;
					font-family: "Sf Pro Display";
					font-weight: 700;
				}

				h5 {
					font-size: 24px;
					font-family: "Sf Pro Display";
					font-weight: 600;
				}
			}
		}
	}
}

common-page {
	.main-containers {
		.display-text-16px {
			.article {
				.article-containers {
					p {
						b {
							font-weight: bold;
						}
						i {
							font-style: italic;
						}
					}
				}
			}
		}
	}
}

app-metiers {
	.metiers {
		.main-containers {
			.left {
				.avis {
					@media (max-width: 414px) {
						flex-direction: column;
					}

					app-statement-card {
						&:first-child {
							margin-right: 30px;
							@media (max-width: 414px) {
								margin: 0;
							}
						}
						.statement-card {
							width: 100%;
							max-width: 100%;
							padding: 20px 30px;
							margin: 0;

							@media (max-width: 414px) {
								margin-bottom: 20px;
							}
						}
					}
				}
			}

			.show-tablet-mobile {
				@media (max-width: 414px) {
					app-contact-us-card {
						.form-contact {
							overflow-y: initial;
							max-height: 100%;

							.form-inputs {
								.phone {
									width: 100%;
								}

								.switch {
									.switch-item {
										flex: 1;
										width: auto;
										span {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	app-faq-container {
		app-faq {
			.faq-title,
			.faq-content {
				b {
					font-weight: bold;
				}
				i {
					font-style: italic;
				}
			}
		}
	}
}

.directions-control-inputs {
	label {
		img {
			display: block;
			margin: auto;
		}
	}
}

.common-page {
	line-height: 19.2px;

	.main-containers.text {
		.display-text-16px {
			h1 {
				font-size: 52px;
				font-weight: 700;
				font-family: "SF Pro Display";

				@media (max-width: constants.$bp-tablet) {
					font-size: 25px;
					line-height: 30px;
					font-weight: 600;
					padding-bottom: 20px;
				}
			}
			h2 {
				font-size: 34px;
				font-weight: 700;
				font-family: "SF Pro Display";
				padding-bottom: 20px;

				@media (max-width: constants.$bp-tablet) {
					font-size: 24px;
					line-height: 32px;
					font-weight: 600;
				}
			}
			h3 {
				font-size: 28px;
				font-weight: 700;
				font-family: "SF Pro Display";
				padding-bottom: 20px;
			}
			h4 {
				font-size: 20px;
				font-weight: 700;
				font-family: "SF Pro Display";
				padding-bottom: 20px;
			}
			i {
				font-style: italic;
			}
			b {
				font-weight: 900;
			}
			ol {
				list-style: none;
				counter-reset: item;
				li {
					font-size: 16px;
					counter-increment: item;
					font-weight: initial;

					&:before {
						content: counter(item);
						width: 1.2em;
						margin-right: 10px;
					}
				}
			}

			ul {
				list-style: inside;
				li {
					font-size: 16px;
					font-weight: initial;

					&:before {
						width: 1.2em;
						margin-right: 10px;
					}
				}
			}

			div {
				font-size: 16px;
				font-weight: initial;
				padding-bottom: 20px;

				br {
					display: none;
				}
			}

			a {
				text-decoration: underline;
				color: var(--color-blue-dark);
			}
		}
	}
}

.blog {
	.main-containers {
		.actualites {
			@media (max-width: constants.$bp-tablet) {
				app-blog-card {
					.box {
						justify-content: inherit;
					}
				}
			}
		}
	}
}

app-contact-agency-card {
	.block-contact-agency {
		app-contact-card {
			.contactContent {
				margin-top: 25px;
			}
		}
	}
}

@media (max-width: 900px) {
	.mapbox-directions-instructions-wrapper {
		max-height: 10vh !important;
	}
}
