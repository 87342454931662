.breadcrumb {
	padding: 45px 40px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 820px) {
		padding: 100px 0 52px 40px;
	}

	&-content {
		display: flex;
		align-items: center;
		width: 100%;
  	gap: 1rem;
	}

	&-back {
		position: relative;
		display: flex;
		cursor: pointer;
		align-items: center;

		&-arrow {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background-color: var(--white);
			box-shadow: 0px 0px 20px 0px #ececec;
			position: relative;
			margin-right: 12px;

			span {
				font-size: 16px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		@media (max-width: 820px) {
			display: none;
		}
	}

	&-links {
		display: flex;
		align-items: center;

		span {
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			padding: 0 3px;
			// text-transform: lowercase;

			&:first-letter {
				text-transform: uppercase;
			}

			&:first-child,
			&.country {
				color: var(--color-grey-super-dark);
			}

			&:last-child {
				font-weight: 600;
				cursor: default;
			}
		}
	}

	&-buttons {
		button {
			margin-right: 10px;

			&[alt="shared"] {
				margin-right: initial;
			}

			&:last-child {
				margin-right: initial;
			}
		}
	}
}

.font-hide {
	color: transparent;
}

.visibility-hidden {
	visibility: hidden;
}

.display-none {
	display: none;
}

.btn {
	padding: 14.5px 37px;
	letter-spacing: 0.01em;
	font-weight: 600;
	font-size: 16px;
	border-radius: 10px;
	cursor: pointer;

	&-red {
		background-color: var(--color-red-dark);
		box-shadow: 0px 5px 25px rgba(145, 3, 55, 0.15);
		color: var(--white);
		border: 1px solid var(--color-red-dark);

		&.disabled,
		&:disabled {
			cursor: default;
			background-color: var(--color-grey-medium);
			border: 1px solid var(--color-grey-medium);
			pointer-events: none;
		}
	}

	&-black {
		background-color: var(--color-red-dark);
		color: var(--white);
		padding: 0 22px;
		min-height: 48px;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
	}

	&-dark-border {
		color: var(--color-grey-su-super-dark);
		min-width: 210px;
		border: solid 1px var(--color-grey-su-super-dark);
		padding: 0 59px;
		min-height: 48px;
		border-radius: 10px;
		font-size: 16px;
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: 0.01em;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;
	}

	&-red-border {
		color: var(--color-red-dark);
		min-width: 210px;
		border: solid 1px var(--color-red-dark);
		padding: 0 59px;
		min-height: 48px;
		border-radius: 10px;
		font-size: 16px;
		font-family: "SF Pro Display";
		font-style: normal;
		font-weight: 600;
		line-height: 19px;
		letter-spacing: 0.01em;
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;

		@media (max-width: 820px) {
			min-width: initial;
			padding: 0 20px !important;
		}
	}

	&-transparent {
		background-color: transparent;
		color: var(--color-grey-su-super-dark);
		border: 1px solid var(--color-grey-su-super-dark);
	}

	&-blue {
		background-color: transparent;
		color: var(--color-blue-dark);
		border: 1px solid var(--color-blue-dark);
	}

	@media (max-width: 820px) {
		padding: 14.5px 20px;
	}
}

.block-contact {
	> div {
		background-color: var(--white);
		border-radius: 10px;
		// box-shadow: 0px 10px 20px 0px var(--box-shadow);
		width: 100%;
	}

	.buttons-tab {
		display: flex;
		align-content: center;
		justify-content: space-between;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		max-width: 585px;

		@media (max-width: 900px) {
			max-width: initial;
		}
	}

	&-title {
		width: 50%;
		cursor: pointer;
		background-color: var(--color-blue-extra-light);
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		padding: 12px 0;
		text-align: center;
		box-shadow: 0px 0px 20px 0px #ececec;

		&.active {
			background-color: var(--white);
			box-shadow: none;
		}
	}

	.tabs-container {
		padding: 30px;
		background-color: var(--color-blue-extra-light);

		@media (max-width: 820px) {
			padding-left: 0;
			padding-top: 0;
			padding-right: 0;
			background-color: var(--white);
		}
	}

	&-tab {
		transition: all 1s linear;
		position: relative;
		display: none;
		opacity: 0;

		&.selected {
			display: block;
			opacity: 1;
			@media (max-width: 820px) {
				margin: auto;
			}
		}

		&.agency.selected {
			display: flex;
			background-color: var(--color-blue-extra-light);
			width: 100%;
			justify-content: center;
			align-items: center;

			app-contact-us-card {
				margin-right: 50px;

				@media (max-width: 820px) {
					margin-right: auto;
					margin-left: auto;
					display: block;
				}
			}

			@media (max-width: 820px) {
				flex-wrap: wrap;
				background-color: var(--white);
			}

			.form-contact {
				max-height: initial;
			}
		}
	}

	&-agency {
		padding: 30px;
		background-color: var(--white);
		border-radius: 10px;

		&-presentation {
			display: flex;

			&-img {
				width: 200px;
				height: 100%;
				margin-right: 30px;
				border-radius: 10px;

				img {
					height: 100%;
					width: 100%;
					object-fit: cover;

					@media (max-width: 820px) {
						width: 100%;
						height: auto;
					}
				}

				@media (max-width: 820px) {
					width: 100%;
					margin: 0 auto 30px auto;
				}
			}

			.display-text-18px {
				font-weight: 600;
				line-height: 22px;
				padding-top: 15px;
				padding-bottom: 10px;
			}
			.display-text-16px {
				line-height: 26px;
			}

			&-button {
				width: fit-content;
				border-radius: 10px;
				margin-top: 10px;

				a {
					width: fit-content;
					color: var(--color-blue-dark);
				}

				@media (max-width: 820px) {
					width: auto;
				}
			}

			&-address {
				@media (max-width: 820px) {
					justify-content: center;
				}
			}

			@media (max-width: 820px) {
				flex-direction: column;
			}
		}
		&-link {
			display: flex;
			justify-content: space-between;
			margin-top: 40px;

			&-button {
				min-width: 264px;
				margin-right: 40px;

				.btn-red {
					margin-bottom: 20px;
				}
			}
			&-view {
				margin-right: 20px;
				width: 280px;
			}
			@media (max-width: 820px) {
				flex-wrap: wrap;
			}
		}
		&-coords {
			background-color: var(--color-blue-extra-light);
			margin: 0 30px 40px 30px;
			padding: 20px;
			border-radius: 10px;

			.btn-phone-blue-border {
				min-width: initial;
				width: 100%;
			}
		}
		&-asterisque {
			padding-top: 40px;
			p {
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
				color: var(--color-grey-medium);
			}
		}

		@media (max-width: 820px) {
			margin: 40px 0;
		}
	}

	.demand {
		&-click {
			text-decoration: underline;
			font-size: 14px;
			line-height: 20px;
		}

		&-text {
			cursor: pointer;
			display: none;
			margin-top: 15px;
			border: 1px solid var(--color-grey-medium);

			&.visible {
				display: block;
			}
		}
	}
}

.form-inputs {
	.colored {
		color: var(--color-red-medium);
	}

	.input {
		margin-bottom: 45px;
		position: relative;

		p {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 0px 8px 8px 0px;
			background-color: var(--color-blue-extra-light);
			color: var(--color-grey-su-super-dark);
			padding: 8px 12px;
			display: flex;
			align-items: center;
		}

		select {
			position: absolute;
			left: 20px;
			z-index: 3;
			top: 0;
			height: 52px;
			width: 96%;

			&:focus-visible {
				outline: none;
			}
		}

		&-label {
			position: relative;
			border: 2px solid var(--color-blue-extra-light);
			padding: 16px 20px;
			width: 100%;
			height: 52px;
			border-radius: 10px;
			background-color: var(--white);

			&.error {
				border-color: var(--color-red-medium);
			}

			input,
			app-tel-input,
			textarea {
				position: relative;
				z-index: 2;
				background: transparent;
				transition: 0.3s all ease-in-out;
				&:focus {
					outline: none;
				}
			}
			textarea {
				height: 156px;
				width: inherit;
			}
			app-tel-input input::placeholder {
				color: transparent;
			}
			input.transformed ~ label,
			textarea.transformed ~ label,
			app-tel-input.transformed ~ label,
			input:focus-within ~ label,
			textarea:focus-within ~ label,
			app-tel-input:focus-within ~ label,
			input[value] ~ label,
			textarea[value] ~ label,
			option:focus-within ~ label,
			option[value] ~ label,
			option.transformed ~ label,
			app-tel-input[value] ~ label {
				transform: scale(0.7) translate(-50px, -50px);
			}
			app-tel-input[value] ~ label.portable {
				transform: scale(0.6) translate(-120px, -44px);

				&:focus {
					transform: scale(0.6) translate(0px, -44px);
				}
			}
			label {
				position: absolute;
				left: 20px;
				top: 10px;
				z-index: 1;
				transition: 0.3s all ease-in-out;
				background: var(--white);
				color: var(--color-grey-medium);

				&.select-label {
					left: -20px;
				}

				span {
					color: var(--color-red-medium);

					&.not-color {
						color: var(--color-grey-medium);
						font-size: 20px;
						position: relative;
						top: 2px;
						margin-right: 10px;
					}
				}

				&.portable {
					left: 120px;
					top: 14px;
				}
			}

			.type-disabled {
				pointer-events: none;
			}

			&.transformed {
				label {
					transform: scale(0.6) translate(-50px, -44px);
				}
			}

			&.textarea {
				height: 156px;
			}

			@media (max-width: 820px) {
				font-size: 14px;
			}
		}
		input {
			width: 100%;

			&::placeholder {
				color: var(--color-grey-super-dark);
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&[type="number"] {
				-moz-appearance: textfield;
				appearance: textfield;
			}
		}

		@media (max-width: 820px) {
			margin-bottom: 30px;
		}

		.input-error {
			position: absolute;
			bottom: 0;
			width: 100%;

			p {
				position: absolute;
				font-size: 12px;
				line-height: 24px;
				color: var(--color-red-medium);
				background-color: transparent;
				left: 0%;
			}
		}
	}

	.input-toggle {
		display: flex;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		padding: 45px 0 69px 0;

		&:first-child {
			font-size: 25px;
			padding-bottom: 25px;
		}

		&-items {
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;

			p {
				padding: 0 5px;
			}
		}

		.toggle {
			position: relative;
			display: inline-block;
			width: 50px;
			height: 26px;
		}

		/* Hide default HTML checkbox */
		.toggle input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		/* The slider */
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: var(--color-grey-li-light);
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		.slider:before {
			position: absolute;
			content: "";
			height: 22px;
			width: 22px;
			left: 4px;
			bottom: 2px;
			background-color: var(--white);
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}

		input:checked + .slider {
			background-color: var(--color-blue-dark);
		}

		input:focus + .slider {
			box-shadow: 0 0 1px var(--color-blue-dark);
		}

		input:checked + .slider:before {
			-webkit-transform: translateX(22px);
			-ms-transform: translateX(22px);
			transform: translateX(22px);
		}

		/* Rounded sliders */
		.slider.round {
			border-radius: 34px;
		}

		.slider.round:before {
			border-radius: 50%;
		}
	}
}

.rgpd {
	color: var(--color-grey-super-dark);

	label {
		position: relative;

		input {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		p {
			padding-left: 30px;
		}
	}
}

.annonce-page {
	.switch {
		input[value] ~ label {
			transform: none;
		}
	}
}
.switch {
	display: flex;
	border: 1px solid var(--color-blue-dark);
	border-radius: 100px;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	margin-bottom: 30px;
	width: max-content;
	margin-left: auto;
	margin-right: auto;

	label {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		width: 184px;
		height: 40px;
		text-align: center;
		margin: 5px;
		border-radius: 100px;
		transition: 0.3s all ease-in;
		cursor: pointer;
		transform: none;

		@media (max-width: 460px) {
			width: 144px;
		}
	}

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		width: 184px;
		height: 40px;
		text-align: center;
		margin: 5px;
		border-radius: 100px;
		transition: 0.3s all ease-in;
		cursor: pointer;

		@media (max-width: 820px) {
			width: 150px;
		}
	}

	input[type="radio"] {
		display: none;
	}

	input[type="radio"]:checked + span {
		background: var(--color-blue-dark);
		color: var(--white);
	}

	input[type="radio"]:checked + label {
		background: var(--color-blue-dark);
		color: var(--white);
		transform: none;
	}
}

.active-checkbox {
	height: 22px;
	width: 22px;
	background-color: var(--color-blue-dark);
	font-size: 18px;
	color: white;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.unactive-checkbox {
	height: 22px;
	width: 22px;
	background-color: white;
	font-size: 18px;
	color: white;
	border: 2px solid #bababa;
	border-radius: 3px;
	cursor: pointer;
	display: flex;
}

.input-geoloc {
	position: absolute;
	left: 0;
	bottom: -200px;
	padding: 10px;
	border-radius: 10px;
	width: 100%;
	border: 2px solid var(--color-blue-extra-light);
	background: var(--white);
	z-index: 4;

	li {
		transition: 0.3s all ease-in-out;
		cursor: pointer;
		line-height: 24px;
		padding: 5px 0;
	}
}

.form-inputs .input-label.not-transformed input[type="number"]:focus-within ~ label {
	transform: none;
}

.container-pictos {
	display: flex;
	flex-wrap: wrap;
	margin: 40px 0;
	margin-bottom: 40px;
	justify-content: flex-start;

	.picto {
		width: 44%;
		box-shadow: 0px 0px 20px 0px var(--box-shadow);
		background-color: var(--white);
		padding: 30px 20px;
		max-width: 230px;
		height: 70px;
		text-align: center;
		margin: 10px;
		display: flex;
		align-items: center;
		border-radius: 10px;

		.display-text-16px {
			font-size: 16px;
			font-weight: 400;
			line-height: 26px;
			letter-spacing: 0.02em;
		}

		[class^="icon-"] {
			font-size: 30px;
			margin-right: 10px;

			@media (max-width: 820px) {
				font-size: 20px;
			}
		}
	}
}

.header-close {
	display: flex;
	text-transform: uppercase;
	align-items: center;
	span,
	p {
		cursor: pointer;
	}
	span {
		height: 32px;
		width: 32px;
		position: relative;
		border-radius: 50%;
		box-shadow: 0px 0px 20px 0px var(--box-shadow);
		margin-right: 12px;
		background-color: var(--white);

		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.estimation-header-close {
	position: absolute;
	height: 40px;
	width: 40px;
	right: 20px;
	top: 20px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 20px 0px var(--box-shadow);
	cursor: pointer;
	background-color: var(--white);
}

.main-containers {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 80px;
	padding-bottom: 80px;
	position: relative;
	margin: auto;

	&.background {
		background-color: var(--color-grey-su-super-light);
		max-width: initial;
		justify-content: center;
		flex-direction: row;

		app-contact-us-card {
			margin-right: 50px;

			@media (max-width: 820px) {
				margin-right: 0;
			}
		}
	}

	@media (min-width: 1700px) {
		padding-right: 10%;
		padding-left: 10%;
	}

	@media (max-width: 1700px) {
		padding-left: 80px;
		padding-right: 80px;
	}

	@media (max-width: 900px) {
		max-width: 100%;
		padding: 20px;
		overflow: hidden;
		padding-top: 40px;
		padding-bottom: 40px;

		// &.carousel {
		// 	padding: 20px 0;
		// }
	}
}

.slider-buttons {
	display: flex;

	button,
	.icon-chevron-left,
	.icon-chevron-right {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: var(--white);
		box-shadow: 0px 0px 20px var(--box-shadow);
		color: var(--color-grey-black);
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-child {
			margin-right: 10px;
		}

		&.desactived {
			pointer-events: none;
			color: #dadada;
		}
	}
}

.grecaptcha-badge {
	z-index: 107;
	visibility: hidden;
}

.changePadding {
	padding-top: 0 !important;
}

.changeIndex {
	z-index: 108 !important;
}
