$header-height: 80px;
$filters-height: 76px;
$header-agence-height: 70px;

$full-header-height: $header-height + $filters-height;
$full-agence-header-height: $header-height + $filters-height + $header-agence-height;
$agence-header-height: $header-height + $header-agence-height;

$bp-phone: 414px;
$bp-tablet: 820px;

$radius: 10px;
