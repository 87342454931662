/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;

	&:focus-visible {
		outline: none;
	}
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.grecaptcha-logo,
.grecaptcha-badge {
	visibility: hidden;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url(/assets/imgs/chevron-down.png) no-repeat;
	background-size: 24px;
	background-position: 100% center;
	background-repeat: no-repeat;
	background-color: transparent;
}

:root {
	// colors
	--color-background-grey: rgba(229, 229, 229, 1);
	--color-red-super-dark: rgba(112, 0, 41, 1);
	--color-red-dark: rgba(145, 3, 55, 1);
	--color-red-medium: rgba(223, 23, 49, 1);
	--color-red-light: rgba(245, 121, 145, 1);
	--color-red-extra-light: rgba(251, 187, 199, 1);
	--color-red-ex-extra-light: rgba(255, 236, 240, 1);
	--color-grey-black: rgba(0, 0, 0, 1);
	--color-grey-su-super-dark: rgba(29, 29, 29, 1);
	--color-grey-super-dark: rgba(68, 68, 68, 1);
	--color-grey-medium: rgba(137, 137, 137, 1);
	--color-grey-light: rgba(186, 186, 186, 1);
	--color-grey-li-light: rgba(218, 218, 218, 1);
	--color-grey-super-light: rgba(243, 243, 243, 1);
	--color-grey-su-super-light: rgba(250, 250, 250, 1);
	--color-grey-white: rgba(255, 255, 255, 1);
	--color-blue-super-dark: rgba(5, 30, 75, 1);
	--color-blue-dark: rgba(12, 50, 121, 1);
	--color-blue-medium: rgba(41, 91, 184, 1);
	--color-blue-light: rgba(225, 232, 238, 1);
	--color-blue-extra-light: rgba(242, 245, 248, 1);
	--color-yellow-super-light: rgba(255, 243, 236, 1);
	--white: #ffffff;
	--box-shadow: #ececec;
	--color-dpe-a: #009641;
	--color-dpe-b: #52ae32;
	--color-dpe-c: #c8d300;
	--color-dpe-d: #ffdf00;
	--color-dpe-e: #fbba00;
	--color-dpe-f: #ec6608;
	--color-dpe-g: #df1731;
	--color-ges-a: #89e0ff;
	--color-ges-b: #79b4d2;
	--color-ges-c: #618db0;
	--color-ges-d: #4a6986;
	--color-ges-e: #39496a;
	--color-ges-f: #282b4a;
	--color-ges-g: #18142b;
}
