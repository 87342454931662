@layer utilities {
  //ICONS
  [class^="icon-"],
  [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "era" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-share1:before {
    content: "\e900";
  }
  .icon-D:before {
    content: "\e901";
  }
  .icon-add:before {
    content: "\e902";
  }
  .icon-appartement:before {
    content: "\e903";
  }
  .icon-archive:before {
    content: "\e904";
  }
  .icon-arrow-down:before {
    content: "\e905";
  }
  .icon-arrow-left-down:before {
    content: "\e906";
  }
  .icon-arrow-left-up:before {
    content: "\e907";
  }
  .icon-arrow-left:before {
    content: "\e908";
  }
  .icon-arrow-right-down:before {
    content: "\e909";
  }
  .icon-arrow-right-up:before {
    content: "\e90a";
  }
  .icon-arrow-right:before {
    content: "\e90b";
  }
  .icon-arrow-up:before {
    content: "\e90c";
  }
  .icon-ascenseur:before {
    content: "\e90d";
  }
  .icon-attachment:before {
    content: "\e90e";
  }
  .icon-backspace:before {
    content: "\e90f";
  }
  .icon-balcon:before {
    content: "\e910";
  }
  .icon-ban:before {
    content: "\e911";
  }
  .icon-bar-chart-alt:before {
    content: "\e912";
  }
  .icon-bar-chart:before {
    content: "\e913";
  }
  .icon-bicycle:before {
    content: "\e914";
  }
  .icon-board:before {
    content: "\e915";
  }
  .icon-bold:before {
    content: "\e916";
  }
  .icon-book:before {
    content: "\e917";
  }
  .icon-bookmark:before {
    content: "\e918";
  }
  .icon-budget:before {
    content: "\e919";
  }
  .icon-bus-outline:before {
    content: "\e91a";
  }
  .icon-calendar:before {
    content: "\e91b";
  }
  .icon-call:before {
    content: "\e91c";
  }
  .icon-camera:before {
    content: "\e91d";
  }
  .icon-caret-down:before {
    content: "\e91e";
  }
  .icon-caret-left:before {
    content: "\e91f";
  }
  .icon-caret-right:before {
    content: "\e920";
  }
  .icon-caret-up:before {
    content: "\e921";
  }
  .icon-cave:before {
    content: "\e922";
  }
  .icon-chambre:before {
    content: "\e923";
  }
  .icon-check:before {
    content: "\e924";
  }
  .icon-chevron-double-down:before {
    content: "\e925";
  }
  .icon-chevron-double-left:before {
    content: "\e926";
  }
  .icon-chevron-double-right:before {
    content: "\e927";
  }
  .icon-chevron-double-up:before {
    content: "\e928";
  }
  .icon-chevron-down:before {
    content: "\e929";
  }
  .icon-chevron-left:before {
    content: "\e92a";
  }
  .icon-chevron-right:before {
    content: "\e92b";
  }
  .icon-chevron-up:before {
    content: "\e92c";
  }
  .icon-circle-add:before {
    content: "\e92d";
  }
  .icon-circle-arrow-down:before {
    content: "\e92e";
  }
  .icon-circle-arrow-left:before {
    content: "\e92f";
  }
  .icon-circle-arrow-right:before {
    content: "\e930";
  }
  .icon-circle-arrow-up:before {
    content: "\e931";
  }
  .icon-circle-check:before {
    content: "\e932";
  }
  .icon-circle-error:before {
    content: "\e933";
  }
  .icon-circle-help:before {
    content: "\e934";
  }
  .icon-circle-information:before {
    content: "\e935";
  }
  .icon-circle-remove:before {
    content: "\e936";
  }
  .icon-circle-warning:before {
    content: "\e937";
  }
  .icon-circle:before {
    content: "\e938";
  }
  .icon-clipboard-check:before {
    content: "\e939";
  }
  .icon-clipboard-list:before {
    content: "\e93a";
  }
  .icon-clipboard:before {
    content: "\e93b";
  }
  .icon-clock:before {
    content: "\e93c";
  }
  .icon-close:before {
    content: "\e93d";
  }
  .icon-cloud-download:before {
    content: "\e93e";
  }
  .icon-cloud-upload:before {
    content: "\e93f";
  }
  .icon-cloud:before {
    content: "\e940";
  }
  .icon-cloudy:before {
    content: "\e941";
  }
  .icon-comment:before {
    content: "\e942";
  }
  .icon-compass:before {
    content: "\e943";
  }
  .icon-computer:before {
    content: "\e944";
  }
  .icon-copy:before {
    content: "\e945";
  }
  .icon-credit-card:before {
    content: "\e946";
  }
  .icon-cuisine:before {
    content: "\e947";
  }
  .icon-database:before {
    content: "\e948";
  }
  .icon-delete-alt:before {
    content: "\e949";
  }
  .icon-delete:before {
    content: "\e94a";
  }
  .icon-document-add:before {
    content: "\e94b";
  }
  .icon-document-check:before {
    content: "\e94c";
  }
  .icon-document-download:before {
    content: "\e94d";
  }
  .icon-document-empty:before {
    content: "\e94e";
  }
  .icon-document-remove:before {
    content: "\e94f";
  }
  .icon-document:before {
    content: "\e950";
  }
  .icon-download:before {
    content: "\e951";
  }
  .icon-drop:before {
    content: "\e952";
  }
  .icon-ecole:before {
    content: "\e953";
  }
  .icon-edit-alt:before {
    content: "\e954";
  }
  .icon-edit:before {
    content: "\e955";
  }
  .icon-email:before {
    content: "\e956";
  }
  .icon-enter:before {
    content: "\e957";
  }
  .icon-etage:before {
    content: "\e958";
  }
  .icon-expand:before {
    content: "\e959";
  }
  .icon-export:before {
    content: "\e95a";
  }
  .icon-external-link:before {
    content: "\e95b";
  }
  .icon-eye-off:before {
    content: "\e95c";
  }
  .icon-eye:before {
    content: "\e95d";
  }
  .icon-facebook:before {
    content: "\e95e";
  }
  .icon-filter-alt:before {
    content: "\e95f";
  }
  .icon-filter:before {
    content: "\e960";
  }
  .icon-flag:before {
    content: "\e961";
  }
  .icon-fog:before {
    content: "\e962";
  }
  .icon-folder-add:before {
    content: "\e963";
  }
  .icon-folder-check:before {
    content: "\e964";
  }
  .icon-folder-download:before {
    content: "\e965";
  }
  .icon-folder-remove:before {
    content: "\e966";
  }
  .icon-folder:before {
    content: "\e967";
  }
  .icon-garage:before {
    content: "\e968";
  }
  .icon-google .path1:before {
    content: "\e969";
    color: rgb(234, 67, 53);
  }
  .icon-google .path2:before {
    content: "\e96a";
    margin-left: -1em;
    color: rgb(52, 168, 83);
  }
  .icon-google .path3:before {
    content: "\e96b";
    margin-left: -1em;
    color: rgb(74, 144, 226);
  }
  .icon-google .path4:before {
    content: "\e96c";
    margin-left: -1em;
    color: rgb(251, 188, 5);
  }
  .icon-grid-text:before {
    content: "\e96d";
  }
  .icon-grid:before {
    content: "\e96e";
  }
  .icon-heart-empty:before {
    content: "\e96f";
  }
  .icon-heart-fill:before {
    content: "\e970";
  }
  .icon-hobbies:before {
    content: "\e971";
  }
  .icon-home:before {
    content: "\e972";
  }
  .icon-image:before {
    content: "\e973";
  }
  .icon-inbox:before {
    content: "\e974";
  }
  .icon-instagram:before {
    content: "\e975";
  }
  .icon-internet:before {
    content: "\e976";
  }
  .icon-italic:before {
    content: "\e977";
  }
  .icon-jardin:before {
    content: "\e978";
  }
  .icon-laptop:before {
    content: "\e979";
  }
  .icon-layers:before {
    content: "\e97a";
  }
  .icon-layout:before {
    content: "\e97b";
  }
  .icon-link-alt:before {
    content: "\e97c";
  }
  .icon-link:before {
    content: "\e97d";
  }
  .icon-linkedin:before {
    content: "\e97e";
  }
  .icon-list:before {
    content: "\e97f";
  }
  .icon-load:before {
    content: "\e980";
  }
  .icon-location:before {
    content: "\e981";
  }
  .icon-lock:before {
    content: "\e982";
  }
  .icon-log-in:before {
    content: "\e983";
  }
  .icon-log-out:before {
    content: "\e984";
  }
  .icon-Logo:before {
    content: "\e985";
  }
  .icon-map:before {
    content: "\e986";
  }
  .icon-megaphone:before {
    content: "\e987";
  }
  .icon-menu:before {
    content: "\e988";
  }
  .icon-mer:before {
    content: "\e989";
  }
  .icon-message-alt:before {
    content: "\e98a";
  }
  .icon-message:before {
    content: "\e98b";
  }
  .icon-minimize:before {
    content: "\e98c";
  }
  .icon-mobile:before {
    content: "\e98d";
  }
  .icon-moon:before {
    content: "\e98e";
  }
  .icon-next:before {
    content: "\e98f";
  }
  .icon-norme-pmr:before {
    content: "\e990";
  }
  .icon-notification-off:before {
    content: "\e991";
  }
  .icon-notification:before {
    content: "\e992";
  }
  .icon-options-horizontal:before {
    content: "\e993";
  }
  .icon-options-vertical:before {
    content: "\e994";
  }
  .icon-orientation:before {
    content: "\e995";
  }
  .icon-parking:before {
    content: "\e996";
  }
  .icon-parking2:before {
    content: "\e997";
  }
  .icon-pause:before {
    content: "\e998";
  }
  .icon-pen:before {
    content: "\e999";
  }
  .icon-percentage:before {
    content: "\e99a";
  }
  .icon-pin:before {
    content: "\e99b";
  }
  .icon-play:before {
    content: "\e99c";
  }
  .icon-position:before {
    content: "\e99d";
  }
  .icon-previous:before {
    content: "\e99e";
  }
  .icon-print:before {
    content: "\e99f";
  }
  .icon-rain:before {
    content: "\e9a0";
  }
  .icon-refresh:before {
    content: "\e9a1";
  }
  .icon-remove:before {
    content: "\e9a2";
  }
  .icon-reorder-alt:before {
    content: "\e9a3";
  }
  .icon-reorder:before {
    content: "\e9a4";
  }
  .icon-repeat:before {
    content: "\e9a5";
  }
  .icon-restaurant:before {
    content: "\e9a6";
  }
  .icon-save-1:before {
    content: "\e9a7";
  }
  .icon-save:before {
    content: "\e9a8";
  }
  .icon-SDB:before {
    content: "\e9a9";
  }
  .icon-search:before {
    content: "\e9aa";
  }
  .icon-select:before {
    content: "\e9ab";
  }
  .icon-send:before {
    content: "\e9ac";
  }
  .icon-settings:before {
    content: "\e9ad";
  }
  .icon-share:before {
    content: "\e9ae";
  }
  .icon-shopping-cart-add:before {
    content: "\e9af";
  }
  .icon-shopping-cart:before {
    content: "\e9b0";
  }
  .icon-shuffle:before {
    content: "\e9b1";
  }
  .icon-snow:before {
    content: "\e9b2";
  }
  .icon-snowflake:before {
    content: "\e9b3";
  }
  .icon-sort:before {
    content: "\e9b4";
  }
  .icon-speakers:before {
    content: "\e9b5";
  }
  .icon-star-empty:before {
    content: "\e9b6";
  }
  .icon-star-fill:before {
    content: "\e9b7";
  }
  .icon-stop:before {
    content: "\e9b8";
  }
  .icon-storm:before {
    content: "\e9b9";
  }
  .icon-strikethrough:before {
    content: "\e9ba";
  }
  .icon-suitcase:before {
    content: "\e9bb";
  }
  .icon-sun:before {
    content: "\e9bc";
  }
  .icon-sunrise-alt:before {
    content: "\e9bd";
  }
  .icon-sunrise:before {
    content: "\e9be";
  }
  .icon-sunset:before {
    content: "\e9bf";
  }
  .icon-switch:before {
    content: "\e9c0";
  }
  .icon-table:before {
    content: "\e9c1";
  }
  .icon-tablet:before {
    content: "\e9c2";
  }
  .icon-tag:before {
    content: "\e9c3";
  }
  .icon-temperature:before {
    content: "\e9c4";
  }
  .icon-terrain:before {
    content: "\e9c5";
  }
  .icon-terrasse:before {
    content: "\e9c6";
  }
  .icon-text:before {
    content: "\e9c7";
  }
  .icon-three-rows:before {
    content: "\e9c8";
  }
  .icon-traffic:before {
    content: "\e9c9";
  }
  .icon-travaux:before {
    content: "\e9ca";
  }
  .icon-twitter:before {
    content: "\e9cb";
  }
  .icon-two-columns:before {
    content: "\e9cc";
  }
  .icon-two-rows:before {
    content: "\e9cd";
  }
  .icon-underline:before {
    content: "\e9ce";
  }
  .icon-undo:before {
    content: "\e9cf";
  }
  .icon-unlock:before {
    content: "\e9d0";
  }
  .icon-user_logged:before {
    content: "\e9d1";
    color: #fff;
  }
  .icon-user-add:before {
    content: "\e9d2";
  }
  .icon-user-check:before {
    content: "\e9d3";
  }
  .icon-user-remove:before {
    content: "\e9d4";
  }
  .icon-user:before {
    content: "\e9d5";
  }
  .icon-users:before {
    content: "\e9d6";
  }
  .icon-volume-off:before {
    content: "\e9d7";
  }
  .icon-volume-up:before {
    content: "\e9d8";
  }
  .icon-walking:before {
    content: "\e9d9";
  }
  .icon-warning:before {
    content: "\e9da";
  }
  .icon-webcam:before {
    content: "\e9db";
  }
  .icon-wind:before {
    content: "\e9dc";
  }
  .icon-window:before {
    content: "\e9dd";
  }
  .icon-youtube:before {
    content: "\e9de";
  }
  .icon-zoom-in:before {
    content: "\e9df";
  }
  .icon-zoom-out:before {
    content: "\e9e0";
  }
}
